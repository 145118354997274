/* customApexCharts.css */

.apexcharts-menu {
    background: #fff !important; 
    color: #000 !important; 
  }
  
  .apexcharts-menu-item {
    color: #000 !important;
  }
  
  .apexcharts-menu-item:hover {
    background: #f1f1f1 !important; 
  }
  