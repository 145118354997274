/* 12px: 12px;
6px: 6px;
#333 #333;
white: white;
#3498db: #3498db;
#3498db-light: lighten(#3498db, 40%);
#DDD: #DDD;

800px: 800px;
450px: 450px;
200px: 200px;

75px: 75px;
(800px - 200px) / 1.5: (800px - 200px) / 1.5;
20px: 20px; */

body {
    /* background-color: #AAA;
    padding: 0; margin: 0;
    width: 100vw;
    height: 100vh;
    color: #333;
    
    display: flex;
    align-items: center;
    justify-content: center; */
  }
  /*   
  * {
    box-sizing: border-box;
    color: #333;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    outline: none;
  }
   */
   body {
    overflow: scroll;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #FF0000;
  }
   /* .app {
    -ms-overflow-style: none; 
    scrollbar-width: none;  
  }
  .app::-webkit-scrollbar { 
    display: none;  
  }
   body::-webkit-scrollbar {
    display: none;
    overflow: hidden;
  }
  .app::-webkit-scrollbar {
    display: none;
    overflow: hidden;
  } */
  .app {
    /* background-color:#DDD; */
    width:100%;
    height: 730px;
    /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); */
    border-radius: 6px;
    margin-top: 1px;
    overflow: hidden;
    
    display: flex;
    /* flex-direction: row; */
    
    .contact-list {
      background-color: white;
      width: 200px;
      overflow: auto;
      
      .title {
        padding: 12px; margin: 0;
        padding-bottom: 0;
        font-size: 150%;
      }
      
      ul {
        list-style: none;
        margin: 0; padding: 12px;
        
        li {
          margin: 12px / 1.5 0;
          padding: 12px / 1.5 12px;
          position: relative;
          cursor: pointer;
          transition: all .2s ease-out;
          
          &.active, &:hover {
            background-color: #3498db;
            color: #3498db;
            border-radius: 6px;
          }
        }
      }
    }
    .messages-history {
      overflow: auto;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;  /* Change to column-reverse */
      align-items: end;
    
      .message {
        width: 100%;
        padding: 12px;
        padding-top: 0;
    
        &:last-child {
          padding-top: 12px;
        }
    
        &.me {
          text-align: right;
    
          .message-body {
            color: black;
            background-color: white;
          }
        }
    
        .message-body {
          background-color: #953131;
          color: white;
          font-weight: 400;
          display: inline-block;
          padding: 12px;
          border-radius: 6px;
          max-width: 600px;
          min-width: 75px;
          line-height: 20px;
          text-align: initial;
        }
      }
    }
    .btns{
      margin-right: 10px;
      text-align: start;
      width: 650px;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 25px;
      margin-top: 10px;
      background-color: rgb(255, 255, 255);
      color: rgb(0, 0, 0);
      outline: none;
    }
    .messages {
      margin: 12px;
      background-color: black;
      border-radius: 6px;
      overflow: hidden;
      
      flex: 1 1 auto;
      
      display: flex;
      flex-direction: column;
      
     
      
      .messages-inputs {
        display: flex;
        
        input[type="text"] {
          background-color: transparent;
          border: none;
          padding: 12px;
          resize: vertical;
          
          flex: 1 1 auto;
        }
        
        button {
          padding: 0 12px;
          background-color: transparent;
          border: none;
          cursor: pointer;
          
          &:hover {
            i {
              color: #3498db;
            }
          }
          
          i {
            vertical-align: middle;
            transition: all .2s ease-out;
          }
        }
      }
    }
  }
  @media screen and (min-width: 320px) {
    .btns {
      width: 280px;
      border: none !important;
      padding: 10px !important;
      color: black;
    }
  }