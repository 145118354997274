.customContainerFluid {
    width: 100%;
    height: 100vh;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #1d1f42;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .colMd4 {
    flex: 0 0 23.333333%;
    max-width: 23.333333%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .colMd8 {
    flex: 0 0 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    justify-content: center;
  }
  .box {
    color: white;
    padding: 38px;
  }
  .box1 {
    background-color: rgba(255, 255, 255, 0.05);
    height: 90vh;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);
    border-radius: 16px;
  }
  .insideBox {
    padding: 20px;
  }
  .image {
    width: 130px;
  }
  .lineCss {
    margin-top: 20px;
    border: 1px solid #636363;
    opacity: 0.5;
  }
  .chatButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 40px 8px 48px;
  
    width: 180px;
    height: 36px;
    border: none;
    outline: none;
    background: #9900ff;
    border-radius: 8px;
    color: white;
  
    flex: none;
    flex-grow: 0;
  }
  .chatButton:focus {
    outline: 0;
  }
  .chatButton1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 8px 40px 8px 48px; */
    padding: 14px;
  
    width: 180px;
    height: 36px;
    border: none;
    outline: none;
    background: #ffffff;
    border-radius: 8px;
    color: rgb(0, 0, 0);
  
    flex: none;
    flex-grow: 0;
  }
  .chatButton1:focus {
    outline: 0;
  }
  
  .nameBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 206px;
    height: 48px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .intialRound {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
  
    width: 48px;
    height: 48px;
  
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100px;
  
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  .namesBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .text1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    margin: 1px;
  }
  .text2 {
    width: 120px;
  
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 10px;
    color: #ffffff;
    opacity: 0.8;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 2px;
  }
  
  .card {
    text-align: center;
  }
  .welcomeText {
    color: white;
    font-family: "Inter";
    font-style: normal;
    font-size: 46px;
    line-height: 58px;
    color: #ffffff;
  }
  .paraText {
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #d8d6d6;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .card {
    text-align: center;
    margin-top: 30px;
  }
  .selectDiv {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .imATag {
    color: white;
    display: flex;
    align-items: center;
    margin-right: 18px;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin-top: 15px;
  }
  .selectCss {
    width: 358px;
    position: relative; 
    height: 50px;
    padding: 13px;
    /* background: rgba(255, 255, 255, 0.2); */
    backdrop-filter: blur(25px);
    border-radius: 12px;
    /* color: rgb(209, 208, 208); */
    cursor: pointer;
    position: relative;
  }
  
  .selectCss::after {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: white transparent transparent transparent;
  }
  .selectCss::before {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: black transparent transparent transparent;
  }
  
  .selectCss:focus {
    outline: none;
  }
  /* .inputFields   {
    width: 720px;
    height: 64px;
  
    margin-top: 30px;
    border-radius: 12px;
    opacity: 0.3;
  } */
  .inputField::placeholder {
    padding: 10px;
  }
  inputField:focus {
    outline: none;
  }
  
  .professionsStyle {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    color: white;
    text-decoration: none;
    
  }
  .linkunderline {
    text-decoration: none;
  }
  .professionDiv {
    height: 148px;
    width: 358px;
    background-color: rgba(255, 255, 255, 0.2);
    text-align: start;
    padding: 20px;
    border-radius: 12px;
    cursor: pointer;
  }
  
  .paraText1{
    color: white;
    text-align: start;
    margin-left: 15px;
  }
  #assumptionsDivs{
    background-color: #515175 !important;
    border: 1px solid #9494a7;
    border-radius: 10px;
    color: white;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
  }
  #assumPs{
    color: white;
    font-size: 14px;
    padding: 10px;
    margin: 0;
    font-weight: bold;
    
  }
  #insideDivs{
    padding: 10px;
    background-color: red;
  }
  .inputContainer {
    position: relative;
    width: fit-content;
    border-radius: 10px; 
  }
  
  .searchButton {
    background-color: #9900ff;
    color: white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 25px;
    bottom: 18px;
    font-size: 20px;
  }
  
  .searchButton span {
    margin-left: 5px;
  }
  .inputFields{
    width: 720px;
    height: 64px;
    margin-top: 30px;
    border-radius: 12px;
    border: none;
    color: white;
    padding: 10px;
    outline: none;
  }
  .inputFields::placeholder{
    color: black;
  }
  .inputfields:focus{
    outline: none;
    border: none;
  }
  
  .arrow {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: transparent transparent black transparent;
  }
  